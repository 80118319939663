const getResults = 'app/profilesNearMe/GET';
const getResultsSuccess = 'app/profilesNearMe/GETSUCCESS';
const getResultsFailure = 'app/profilesNearMe/GETFAILURE';
const getMoreResults = 'app/profilesNearMe/GETMORE';
const getMoreResultsSuccess = 'app/profilesNearMe/GETMORESUCCESS';
const getMoreResultsFailure = 'app/profilesNearMe/GETMOREFAILURE';

export default {
  getResults,
  getResultsSuccess,
  getResultsFailure,
  getMoreResults,
  getMoreResultsSuccess,
  getMoreResultsFailure,
};
