import { combineReducers } from 'redux';
import { friendsTypes } from '../friends';
import { swipesTypes } from '../swipes';
import { friendShipStatus } from '../enums';
import types from './types';

const profilesReducer = (state = [], action) => {
  switch (action.type) {
    case types.getResultsSuccess:
      return action.results;
    case types.getMoreResultsSuccess:
      return [...state, ...action.results];
    case friendsTypes.rejectFriendSuccess:
    case friendsTypes.removeFriendSuccess:
    case friendsTypes.sendFriendRequestSuccess:
    case friendsTypes.retreatFriendRequestSuccess:
    case friendsTypes.addFriendSuccess:
    case swipesTypes.swipeRight: {
      const { id } = action;
      return state.map((item) => (item.id === id ? resultReducer(item, action) : item));
    }
    default:
      return state;
  }
};

const resultReducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case swipesTypes.swipeRight:
      return { ...state, isLiked: true };
    case friendsTypes.sendFriendRequestSuccess:
      return {
        ...state,
        friendRequestStatus: friendShipStatus.pending,
        friendshipCounter: state.friendshipCounter ? state.friendshipCounter + 1 : 1,
      };
    case friendsTypes.removeFriendSuccess:
    case friendsTypes.retreatFriendRequestSuccess:
      return { ...state, friendRequestStatus: friendShipStatus.noRequest };
    case friendsTypes.addFriendSuccess:
      return { ...state, friendRequestStatus: friendShipStatus.accepted };
    case friendsTypes.rejectFriendSuccess:
      return { ...state, friendRequestStatus: friendShipStatus.denied };
    default:
      return state;
  }
};

const moreRecordsReducer = (state = true, action) => {
  switch (action.type) {
    case types.getMoreResultsSuccess:
    case types.getResultsSuccess:
      return action.moreRecords;

    default:
      return state;
  }
};

const nextPageReducer = (state = 1, action) => {
  switch (action.type) {
    case types.getResultsSuccess:
      return 2;
    case types.getMoreResultsSuccess:
      return state + 1;
    default:
      return state;
  }
};

const isLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case types.getMoreResults:
    case types.getResults:
      return true;
    case types.getResultsSuccess:
    case types.getResultsFailure:
    case types.getMoreResultsSuccess:
    case types.getMoreResultsFailure:
      return false;

    default:
      return state;
  }
};

export default combineReducers({
  profiles: profilesReducer,
  moreRecords: moreRecordsReducer,
  nextPage: nextPageReducer,
  isLoading: isLoadingReducer,
});
