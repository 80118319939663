import types from './types';

const getResults = (settings) => ({ type: types.getResults, settings });
const getResultsSuccess = (results, moreRecords, nextCursor) => ({
  type: types.getResultsSuccess,
  results,
  moreRecords,
  nextCursor,
});
const getResultsFailure = (error) => ({ type: types.getResultsFailure, error });

const getMoreResults = (settings, nextCursor) => ({
  type: types.getMoreResults,
  settings,
  nextCursor,
});
const getMoreResultsSuccess = (results, moreRecords, nextCursor) => ({
  type: types.getMoreResultsSuccess,
  results,
  moreRecords,
  nextCursor,
});
const getMoreResultsFailure = (error) => ({ type: types.getMoreResultsFailure, error });

export default {
  getResults,
  getResultsSuccess,
  getResultsFailure,
  getMoreResults,
  getMoreResultsSuccess,
  getMoreResultsFailure,
};
