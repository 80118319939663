import { takeLeading, takeLatest, put, call } from 'redux-saga/effects';
import types from './types';
import actions from './actions';
import { searchUser } from '../services/webAppEndPoint';

const perPage = 24;
const _nextCursor = 1;
const _shuffleResults = true;

function* getResults({ settings }) {
  try {
    const { results, moreRecords, nextCursor } = yield call(searchUser, settings, perPage, _nextCursor, _shuffleResults);
    yield put(actions.getResultsSuccess(results, moreRecords, nextCursor));
  } catch (error) {
    yield put(actions.getResultsFailure(error));
  }
}

function* handleGetResults() {
  yield takeLatest(types.getResults, getResults);
}

function* getMoreResults({ settings, nextCursor }) {
  try {
    const { results, moreRecords, nextCursor: nextCursorr } = yield call(
      searchUser,
      settings,
      perPage,
      nextCursor,
      _shuffleResults
    );
    yield put(actions.getMoreResultsSuccess(results, moreRecords, nextCursorr));
  } catch (error) {
    yield put(actions.getMoreResultsFailure(error));
  }
}

function* handleGetMoreResults() {
  yield takeLeading(types.getMoreResults, getMoreResults);
}

export default {
  handleGetMoreResults,
  handleGetResults,
};
