import { createStructuredSelector } from 'reselect';
import { searchsettingsSelectors } from '../searchsettings';

const getProfilesNearMePart = state => state.profilesNearMe;
const getProfiles = state => getProfilesNearMePart(state).profiles;
const getMoreRecords = state => getProfilesNearMePart(state).moreRecords;
const getNextPage = state => getProfilesNearMePart(state).nextPage;
const getIsLoading = state => getProfilesNearMePart(state).isLoading;

const getProfilesNearMe = createStructuredSelector({
  profiles: getProfiles,
  moreRecords: getMoreRecords,
  nextPage: getNextPage,
  isLoading: getIsLoading,
  settings: searchsettingsSelectors.getSettings,
});

export default { getProfilesNearMe };
